<template>
  <FilterOverview :title="'KPI Dashboard'" :isInitiallyLoading="false">
    <template #toolbar-left>
      <b-button-group size="sm" style="width: 300px" class="mr-4">
        <b-button
          v-for="kpiSite in kpiSites"
          :disabled="isBusy"
          :key="kpiSite"
          :id="kpiSite"
          :variant="kpiSite === 'Dashboard' ? 'primary' : ''"
          class="mr-1"
          :to="'/reisetermine/fvc/kpi-' + kpiSite.toLowerCase()"
          style="width: 33%"
        >
          {{ kpiSite }}
        </b-button>
      </b-button-group>
      <DateRangePicker
        :disabled="isBusy"
        isWeeklyDaterangePicker
        style="width: 180px"
        placeholder="Zeitraum"
        :value="zeitraumSelected"
        @input="setZeitraum"
      />
    </template>

    <template #filter><KPIFilter /></template>

    <template #table="{ tableHeight, heightComponentInlineStyle }">
      <div class="" :style="heightComponentInlineStyle" style="overflow-y: scroll; padding: 10px">
        <b-overlay :show="isBusy" opacity="1">
          <div class="d-flex flex-wrap mb-5" style="gap: 10px">
            <DoughnutChart
              style="width: 33%; height: 400px; flex-grow: 1"
              :data="isBusy ? [] : getGesamtverteilungStatusData"
              :fillArray="['#ebcc87', '#c16068', '#DAA17B', '#a2bf8a']"
              :title="'Gesamtverteilung Status'"
            />
            <DoughnutChart
              style="width: 33%; height: 400px; flex-grow: 1"
              :data="isBusy ? [] : getBearbeitungsData"
              title="Bearbeitete ABFHT"
              :innerLabelSubtitle="'bearbeitet'"
              :fillArray="['#a2bf8a', '#5a7f41', '#c16068', '#9d4f55', '#1D4E89', '#2660a7', '#D3D5D7']"
              :showPercentage="true"
              :ausschlussTyp="['Nicht bearbeitet']"
            />
            <StackedColumnChart
              style="width: 33%; height: 400px; flex-grow: 1"
              :data="getColumnChartAkzeptanzgrundData"
              title="Verteilung Akzeptanzgründe"
              :stacked="true"
            />
            <StackedColumnChart
              style="width: 33%; height: 400px; flex-grow: 1"
              :data="getColumnChartUrsachenData"
              title="Verteilung Ursachen"
              :stacked="true"
            ></StackedColumnChart>

            <DoughnutChart
              style="width: 33%; height: 400px; flex-grow: 1"
              :data="isBusy ? [] : getAufpreisBigger200Data"
              :fillArray="['#ebcc87', '#DAA17B', '#c16068']"
              :title="'Aufpreis >200'"
            />
            <DoughnutChart
              style="width: 33%; height: 400px; flex-grow: 1"
              :data="isBusy ? [] : getAufpreisSmaller200Data"
              :fillArray="['#40513B', '#609966', '#a2bf8a']"
              :title="'Aufpreis <200'"
            />
          </div>
        </b-overlay>
      </div>
    </template>
  </FilterOverview>
</template>
<script>
import { AgChartsVue } from 'ag-charts-vue';
import DoughnutChart from '@/components/flugverfuegbarkeit/doughnut-chart.vue';
import KPIFilter from '@/components/flugverfuegbarkeit/kpi-filter.vue';
import BarChart from '@/components/flugverfuegbarkeit/bar-chart.vue';
import FilterOverview from '@/components/common/filter-overview.vue';
import {
  GET_DATA_WITH_ALL_GROUPINGS,
  SET_ZEITRAUM_FILTER,
} from '@/core/flugverfuegbarkeit/stores/kpi-fvc.module';
import { mapGetters, mapState } from 'vuex';
import SimpleProgressChart from '@/components/flugverfuegbarkeit/simple-progress-chart.vue';
import MultiBarChart from '@/components/flugverfuegbarkeit/multi-bar-chart.vue';
import DateRangePicker from '@/components/common/date-range-picker.vue';
import StackedColumnChart from '@/components/flugverfuegbarkeit/stacked-column-chart.vue';

export default {
  components: {
    DoughnutChart,
    BarChart,
    FilterOverview,
    StackedColumnChart,
    KPIFilter,
    AgChartsVue,
    SimpleProgressChart,
    MultiBarChart,
    DateRangePicker,
  },
  data() {
    return {
      regionsmanagerFilterSelected: '',
      options: null,
      options2: null,
      kpiSites: ['Dashboard', 'Leading', 'Historie'],
    };
  },

  computed: {
    ...mapGetters([
      'getColumnChartUrsachenData',
      'getGesamtverteilungStatusData',
      'getAufpreisBigger200Data',
      'getAufpreisSmaller200Data',
      'getColumnChartAkzeptanzgrundData',
      'getBearbeitungsData',
      'getVerfuegbarkeitsData',
      'getTopFiveUrsachen',
    ]),
    ...mapState({
      data: state => state.kpiFvc.data.allGroupedData,
      isBusy: state => state.kpiFvc.isBusy,
      zeitraumSelected: state => state.kpiFvc.filters.zeitraum,
    }),
  },
  created() {
    if (this.data.length === 0) this.$store.dispatch(GET_DATA_WITH_ALL_GROUPINGS);
  },
  methods: {
    changeRegionsmanagerFilter(kuerzel) {
      this.regionsmanagerFilterSelected = kuerzel;
    },
    setZeitraum(start, end) {
      if (start !== this.zeitraumSelected.start || end !== this.zeitraumSelected.end) {
        this.$store.commit(SET_ZEITRAUM_FILTER, {
          start: start || null,
          end: end || null,
        });
        this.$store.dispatch(GET_DATA_WITH_ALL_GROUPINGS);
      }
    },
  },
};
</script>
<style></style>
